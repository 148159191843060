.home {
  display: block;
  margin: 0 auto;

  .selection {
    display: block;
    margin: 0 auto;
    margin-bottom: 50px;
    text-align: center;
    select {
      padding: 5px 10px;
      background-color: #d9d9d9;
      border: none;
      border-radius: 10px;
    }
  }
  .results {
    .payee {
      display: block;
      margin: 0 auto;
      max-width: 350px;
      margin-bottom: 20px;
      .payee-name {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 20px;
        font-size: 20px;
        img {
          width: 20px;
          height: 20px;
          margin: 0;
          margin-right: 20px;
          transition: 0.4s;
        }
      }

      .entries {
        display: none;
      }
      .amount-total{
        display: none;
      }
      &.border {
        border: 1px solid #2e2e2e;
        padding: 15px;
        border-radius: 10px;
      }
      &.active {
        .payee-name {
          img {
            transform: rotate(180deg);
          }
        }
        .entries {
          display: block;
        }
        
    .amount-total {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 35px;
      padding-top: 10px;
      padding-left: 10px;
      border-top: 1px solid #2e2e2e;
    }
      }
    }
    .results-evaluation {
      display: block;
      margin: 0 auto;
      max-width: 350px;

      h4 {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 25px;
      }
      p {
        font-size: 16px;
        margin-bottom: 10px;
      }
      &.border {
        border: 1px solid #2e2e2e;
        padding: 15px;
        border-radius: 10px;
        margin-bottom: 50px;
      }
    }
  }
}
