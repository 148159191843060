* {
    margin: 0;
    padding: 0;
}

/* roboto-300 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url('../assets/fonts/roboto-v30-latin-300.eot');
    /* IE9 Compat Modes */
    src: local(''),
        url('../assets/fonts/roboto-v30-latin-300.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('../assets/fonts/roboto-v30-latin-300.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('../assets/fonts/roboto-v30-latin-300.woff') format('woff'),
        /* Modern Browsers */
        url('../assets/fonts/roboto-v30-latin-300.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../assets/fonts/roboto-v30-latin-300.svg#Roboto') format('svg');
    /* Legacy iOS */
}

/* roboto-regular - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('../assets/fonts/roboto-v30-latin-regular.eot');
    /* IE9 Compat Modes */
    src: local(''),
        url('../assets/fonts/roboto-v30-latin-regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('../assets/fonts/roboto-v30-latin-regular.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('../assets/fonts/roboto-v30-latin-regular.woff') format('woff'),
        /* Modern Browsers */
        url('../assets/fonts/roboto-v30-latin-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../assets/fonts/roboto-v30-latin-regular.svg#Roboto') format('svg');
    /* Legacy iOS */
}

/* roboto-700 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url('../assets/fonts/roboto-v30-latin-700.eot');
    /* IE9 Compat Modes */
    src: local(''),
        url('../assets/fonts/roboto-v30-latin-700.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('../assets/fonts/roboto-v30-latin-700.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('../assets/fonts/roboto-v30-latin-700.woff') format('woff'),
        /* Modern Browsers */
        url('../assets/fonts/roboto-v30-latin-700.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../assets/fonts/roboto-v30-latin-700.svg#Roboto') format('svg');
    /* Legacy iOS */
}

.App {
    display: block;
    margin: 0 auto;
    width: 95%;

    h1 {
        font-size: 32px;
        margin: 50px auto 0px;
        font-family: 'Roboto';
        text-align: center;
    }

    img {
        display: block;
        margin: 0 auto;
        height: 200px;
        width: 200px;
        object-fit: cover;
    }

    .container {
        display: flex;
        justify-content: flex-end;

        nav {}

        .hamburger {
            position: relative;
            display: block;
            width: 35px;
            cursor: pointer;
            appearance: none;
            background: none;
            outline: none;
            border: none;
            margin-top: 10px;
            z-index: 99;

        }

        .hamburger .bar,
        .hamburger::after,
        .hamburger::before {
            width: 100%;
            height: 3px;
            background-color: #000;
            margin-bottom: 6px;
            transition: 0.4s;
            content: '';
            display: block;
        }

        .hamburger.is-active:before {
            transform: rotate(-45deg) translate(-16px, 5px);
            background-color: #fff;

        }

        .hamburger.is-active:after {
            transform: rotate(45deg) translate(-8px, 3px);
            background-color: #fff;

        }

        .hamburger.is-active .bar {
            opacity: 0;
        }

        .mobile-nav {
            display: block;
            position: fixed;
            top: 0;
            left: 100%;
            width: 100%;
            min-height: 100vh;
            z-index: 98;
            background-color: #95AEC6;
            transition: 0.4s;

            ul {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: block;
                margin: 0 auto;
                list-style: none;
            }

            a {
                display: block;
                margin-bottom: 20px;
                text-align: center;
                font-size: 20px;
                color: #F2F5F8;
                padding: 16px 32px;
            }

            &.is-active {
                left: 0;

            }
        }
    }
}

@media screen and (min-width: 600px) {
    .App {
        .mobile-nav {
            ul {
                width: 15%;
            }
        }
    }
}