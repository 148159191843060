.upload {
  margin-bottom: 50px;
  .upload_status{
    display: none;
    width: 90%;
    max-width: 350px;
    margin: 20px auto;
    text-align: center;

    p{
      padding: 15px;
      border-radius: 15px;
      &.error{
        background-color: rgba(255, 0, 0, 0.2);
        color: rgba(228, 0, 0, 0.7);

      }
      &.success{
        background-color: rgba(46, 228, 0, 0.2);
        color: rgba(43, 165, 0, 1);
      }
    }
  }
  form {
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 350px;
    margin: 0 auto;
    label{
        margin-bottom: 5px;
    }
    input,
    select {
      margin-bottom: 20px;
      border: 1px solid #2e2e2e;
      border-radius: 5px;
      padding: 5px;
    }
  }
}
