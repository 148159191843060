.single-entry-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 35px;
  padding: 5px 10px 5px;
  margin-bottom: 5px;
  border-bottom: 1px outset rgba(228,218,208,0.35);
  &:last-of-type{
    border-bottom: none;
  }
  .right {
    p {
      margin-bottom: 5px;

      &.tag {
        display: block;
        width: fit-content;
        padding: 3px 5px;
        border-radius: 100px;
      }
      &.champagne {
        color: #2e2e2e;
        background-color: #eee0cb;
      }
      &.timberwolf{
        color: #2e2e2e;
        background-color: #DDD0C7;
      }
      &.grullo {
        color: #2e2e2e;
        background-color: #baa898;
      }
      &.spanish_grey {
        color: #fff;
        background-color: #9f978f;
      }
      &.battleship_grey {
        color: #fff;
        background-color: #848586;
      }
      &.old_rose {
        color: #fff;
        background-color: #c2847a;
      }
      &.dark_sienna {
        color: #fff;
        background-color: #280003;
      }
      &.dark_sienna_l {
        background-color: #3c171a;
        color: #fff;
      }
      &.rosy_brown {
        background-color: #BC908E;
        color: #fff;
      }
    }
  }
}
